class PedidoProveedorDetalle {
    constructor({ id, pedido_from_codigo, fk_pedido_from, fk_material, unidades, coste, fk_pedido_proveedor } = {}) {
        this.id = id
        this.fk_pedido_from = fk_pedido_from
        this.fk_material = fk_material
        this.unidades = unidades
        this.coste = coste
        this.fk_pedido_proveedor = fk_pedido_proveedor
        this.pedido_from_codigo = pedido_from_codigo
    }
}

export default PedidoProveedorDetalle